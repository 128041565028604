.intro {
    background-color: #f4f4f4;
    padding: 2rem;
    text-align: center;
  }
  
  .intro-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .intro h1 {
    font-size: 2.5rem;
  }
  
  .intro p {
    font-size: 1.2rem;
  }
  