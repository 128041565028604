header {
    background: #1f1f1f;
    color: #e0e0e0;
    padding: 2rem;
    text-align: center;
    border-bottom: 4px solid #bb86fc;
  }
  
  header h1 {
    margin: 0;
    font-size: 2.5rem;
    font-family: 'Arial', sans-serif;
  }
  
  header p {
    margin: 0.5rem 0 0;
    font-size: 1.2rem;
  }
  