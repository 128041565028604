.education {
    padding: 2rem;
  }
  
  .education h2 {
    text-align: center;
  }
  
  .education-card {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1rem;
    background-color: #fff;
  }
  