footer {
    background: #1f1f1f;
    color: #e0e0e0;
    padding: 1rem;
    text-align: center;
    border-top: 4px solid #bb86fc;
  }
  
  footer p {
    margin: 0;
    font-size: 1rem;
  }
  