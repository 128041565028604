body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  header {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
  
  header h1 {
    margin: 0;
  }
  
  header p {
    margin: 0.5rem 0;
  }
  
  main {
    padding: 2rem;
  }
  
  .intro {
    background-color: #f4f4f4;
    padding: 2rem;
    text-align: center;
  }
  
  .intro-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .skills {
    padding: 2rem;
  }
  
  .chart-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .experience {
    padding: 2rem;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .timeline-item {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .education {
    padding: 2rem;
  }
  
  .education-card {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .certifications {
    padding: 2rem;
  }
  
  .certification-card {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .contact {
    padding: 2rem;
  }
  
  .contact form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact label {
    display: block;
    margin: 0.5rem 0 0.2rem;
  }
  
  .contact input, .contact textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact button {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact button:hover {
    background-color: #555;
  }
  
  footer {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
  }
  
  footer p {
    margin: 0;
  }
  