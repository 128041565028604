.skills {
    padding: 2rem;
    background: #181818;
  }
  
  .skills h2 {
    text-align: center;
    color: #e0e0e0;
    font-size: 2rem;
    margin-bottom: 1rem;
    font-family: 'Arial', sans-serif;
  }
  
  .chart-container {
    max-width: 90%;
    margin: 0 auto;
  }
  
  @media (max-width: 600px) {
    .skills h2 {
      font-size: 1.5rem;
    }
  
    .chart-container {
      width: 100%;
    }
  }
  