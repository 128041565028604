.contact {
    padding: 2rem;
  }
  
  .contact h2 {
    text-align: center;
  }
  
  .contact form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact label {
    display: block;
    margin: 0.5rem 0 0.2rem;
  }
  
  .contact input, .contact textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact button {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact button:hover {
    background-color: #555;
  }
  