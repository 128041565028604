.experience {
    padding: 2rem;
  }
  
  .experience h2 {
    text-align: center;
  }
  
  .timeline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .timeline-item {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }
  